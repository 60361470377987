<fly-card
  class="search-card"
  [selected]="isSelected"
  (selectedChange)="onSelect()"
  [padding]="'reduced'">
  <div class="wrapper">
    <div class="icon">
      <fly-icon [icon]="icon"></fly-icon>
    </div>
    <div class="data">
      <span>{{ identifier }}</span>
      <span class="body-3">{{ location }}</span>
    </div>
  </div>
</fly-card>
