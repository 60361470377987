import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { FlightIcons, FlyIconModule, Icon } from '@garmin-avcloud/avcloud-ui-common/icon';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { RouteComputedLeg } from '@generated/flight-route-service';
import { getWaypointIcon } from '@shared/components/search/search.utils';
import { ExtendedTitleCasePipe } from "../../../pipes/extended-title-case/extended-title-case.pipe";

@Component({
  selector: 'pilot-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  standalone: true,
  imports: [CommonModule, FlyCardModule, FlyIconModule]
})

export class SearchCardComponent implements OnInit {
  @Input() showNextArrow?: boolean;
  @Input() waypoint: RouteComputedLeg;
  @Output() readonly selected = new EventEmitter<SearchCardComponent>();

  private readonly titleCasePipe = new ExtendedTitleCasePipe();
  readonly FlightIcons = FlightIcons;
  protected icon: Icon;
  protected isSelected = false;
  protected location = '';
  protected identifier = '';

  ngOnInit(): void {
    this.icon = getWaypointIcon(
      {
        type: this.waypoint.locationType ?? '',
        identifier: this.waypoint.identifier,
        navaidType: this.waypoint.navaidType
      }
    ) as Icon;

    this.createLocationString();

    this.identifier = this.waypoint.identifier;
    if (isStringNonEmpty(this.waypoint.displayName)) {
      const displayName = this.titleCasePipe.transform(this.waypoint.displayName);
      this.identifier = `${this.identifier} - ${displayName}`;
    }
  }

  private createLocationString(): void {
    const locationList = [];

    if (isStringNonEmpty(this.waypoint.city)) {
      locationList.push(`${this.titleCasePipe.transform(this.waypoint.city)},`);
    }

    if (isStringNonEmpty(this.waypoint.state)) {
      locationList.push(this.waypoint.state);
    }

    /**
     * Special case for USA waypoints. ACL stores the country name with the region
     * for waypoints in the US but we only need the name. Example: N CEN USA
     */
    const usaIndex = this.waypoint.countryDesc?.indexOf('USA') ?? -1;
    if (usaIndex > 0) {
      locationList.push(this.waypoint.countryDesc?.slice(usaIndex));
    } else {
      const country = this.titleCasePipe.transform(this.waypoint.countryDesc);
      locationList.push(country);
    }

    this.location = locationList.join(' ');
  }

  onSelect(): void {
    this.selected.emit(this);
    this.isSelected = true;
  }

  unselect(): void {
    this.isSelected = false;
  }

}
